import ShopaholicSearch from "@lovata/shopaholic-search";

const obHelper = new ShopaholicSearch();
obHelper
    .setSearchLimit(2)
    .setSearchDelay(300)
    .setAjaxRequestCallback(function(obRequestData) {
        // obRequestData.loading = '.preloader';
        obRequestData.update = {'site/header/search/list': '.search-list'};

        return obRequestData;
    }).init();
