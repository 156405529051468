import ShopaholicProductList from "@lovata/shopaholic-product-list/shopaholic-product-list";
import ShopaholicPagination from "@lovata/shopaholic-product-list/shopaholic-pagination";
import ShopaholicSorting from "@lovata/shopaholic-product-list/shopaholic-sorting";
import ShopaholicFilterPanel from "@lovata/shopaholic-filter-panel/shopaholic-filter-panel";
import ShopaholicFilterPrice from "@lovata/shopaholic-filter-panel/shopaholic-filter-price";
import UrlGeneration from "@lovata/url-generation";

// const obListHelper = new ShopaholicProductList();
// obListHelper.setAjaxRequestCallback((obRequestData) => {
//     obRequestData.update = {
//         'page/catalog/products/products': '.catalog-content'
//     };
//
//     return obRequestData;
// });
//
// const obFilterPanel = new ShopaholicFilterPanel(obListHelper);
// obFilterPanel.init();

export default new class catalog {
    constructor() {
        this.mainWrapper = document.getElementsByClassName('catalog-flex');
        this.sFilterWrapper = 'catalog-sidebar';
        this.sCatalogWrapper = 'catalog-content';
        this.preloaderSelector = 'preloader';
        this.init();
        UrlGeneration.init();
    }

    init() {
        const obListHelper = new ShopaholicProductList();
        obListHelper.setAjaxRequestCallback((obRequestData) => {
            obRequestData.update = {
                'page/catalog/filter/filter': `.${this.sFilterWrapper}`,
                'page/catalog/products/products': `.${this.sCatalogWrapper}`
            };
                obRequestData.loading = `.${this.preloaderSelector}`;
                obRequestData.complete = () => {
                    $("html, body").animate({ scrollTop: this.mainWrapper['0'].offsetTop }, "slow");
                    $('#filterRangePrice').each(function () {
                        $(this).slider({
                            range: true,
                            min: $(this).data("min"),
                            max: $(this).data("max"),
                            step: 100,
                            values: [$('#PriceBegin').val(), $('#PriceEnd').val()],
                            change: function (event, ui) {
                                $('#PriceBegin').val(ui.values[0]);
                                $('#PriceEnd').val(ui.values[1]).change();
                            }
                        });
                    });
                }
            return obRequestData;
        });

        const obFilterPanel = new ShopaholicFilterPanel(obListHelper);
        obFilterPanel.init();

        const obFilterPrice = new ShopaholicFilterPrice(obListHelper);
        obFilterPrice.init();

        const obSortingHelper = new ShopaholicSorting(obListHelper);
        obSortingHelper.init();

        const obPaginationHelper = new ShopaholicPagination(obListHelper);
        obPaginationHelper.init();


    }

}
